import React from "react";
import {  Avatar, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Container } from "@mui/material";
import { ServerWebSocket } from "../WS/ws";
import { Button } from "@mui/material";
import { sendServerRequest } from "../utils/sendreq";
import { URLS } from "../env/urls";
import { CircularProgress } from "@mui/material";
import { getDeviceInfo } from "./getDeviceInfo";
import { changeLocationPathName } from "../utils/location";
import { changePathIfLicenseIsExpired } from "../utils/user";
export class WhatsAppConnection extends React.Component{
     constructor(props){
         super(props);
         this.state={isLoading:true,
                     deviceInfo:null,
                     isConnected:false,
                    }  
         this.initialState={};
         Object.assign(this.initialState,this.state);       
     }
     componentDidMount(){
        this.checkConnection();
     }
     componentWillUnmount(){

     }
     async updateClock(maxTime=300000){
         if(this.lastClockId)
            clearInterval(this.lastClockId);
         this.clockStart=new Date();
         this.clockMaxTime=maxTime;   
         this.lastClockId=setInterval(()=>{
              let now=new Date();
              let timeLeft=Math.max(0,this.clockMaxTime-(now.getTime()-this.clockStart.getTime()))/1000;
              timeLeft=Math.floor(timeLeft);
              this.setState({timeLeft});   
         },1000)
         
     }
     update(){
         this.setState(this.initialState);
         this.checkConnection();
     }
     async checkConnection(){
        let newState={isLoading:false};
        try{
             await this.setState({isLoading:true});
             const host=await getDeviceInfo();
             const {data}=host;
             if(data.hasError)
                throw host;
             newState.deviceInfo=data.data.host;
             newState.isConnected=true;
        }catch(e){
            console.log(e);
        }
       this.setState(newState); 

     }
     async disconnect(clientId=1,waLib="WA_WEB_JS"){
        let result=await sendServerRequest(URLS.session.destroy,JSON.stringify({
            clientId,
            waLib
        }));
        console.log(result);
        this.update();
     }
     WhatsInfo(){
         return(
           <React.Fragment>
              {this.state.isConnected?
                <React.Fragment>
                   <DeviceInfo deviceInfo={this.state.deviceInfo}/>
                   
                </React.Fragment>
                :<ConnectWhatsApp update={()=>{this.update()}}/>
              }
           </React.Fragment> 
         )
     }
     render(){
         const WhatsInfo=this.WhatsInfo.bind(this);
         return (<div style={{marginTop:"50px",textAlign:"center"}}>
             {this.state.isLoading?
                <React.Fragment>
                    <CircularProgress/>
                    <Clock message="Verificando conexão esse processo pode demorar até" maxTime={300}/>
                </React.Fragment>
             :<WhatsInfo/>}
                 <div style={{textAlign:"center",marginTop:"80px"}}>
                 <Typography>Caso esteja com problema, clique no botão para reiniciar o sistema.</Typography>
                  <Button variant="contained" onClick={()=>this.disconnect()}>Reiniciar</Button>
                </div>
                </div>)
     }
    
}

class ConnectWhatsApp extends React.Component{
    constructor(props){
        super(props);
        this.state={loading:false,
                    infoText:"Clique para conectar o WhatsApp",
                    qrCodeData:null,
                    }
        
    }
    start(){
        this.setState({loading:true,infoText:"Conectando.."})
        this.ws=new ServerWebSocket(getWsUrl());
        this.ws.setOnclose(()=>{
            this.setState(this.initialState);
        });
        this.ws.setOnopen(()=>{
            try{
               this.ws.auth();
               //this.ws.sendPing();
            }catch(e){

            }   
        });
        this.ws.setOnmessage((msg,ws)=>{
            console.log(msg);
            if(msg.hasError){
                this.ws.close();
                alert("Erro na conexão");
                return;
            }
             switch(msg.data.response){
                case "Authenticated":
                   this.ws.send(JSON.stringify({action:'connect',clientId:1,waLib:'WA_WEB_JS'}));  
                   break;
                case 'qr_updated':
                   this.setState({infoText:"Escaneie o QRCode com o seu WhatsApp",
                                  qrCodeData:msg.data.data.qr});
                   break;                 
                case 'connected':
                   this.props.update();
                   break;        
             }
        });
        this.ws.setOnclose((r)=>{
            this.setState({
                loading:false,
                infoText:"Conexão perdida, tente novamente.",
                qrCodeData:null,
            })
         });
    }
    
    
    closeSnack(){
        this.setState({snack:{open:false}});
    }
    componentWillUnmount(){
        console.log("will um");
        if(this.ws&&this.ws.close)
           this.ws.close();
    }
    render(){
        return (
         <Container maxWidth='xs' sx={{marginTop:'50px',textAlign:'center'}}>
          <LoadingButton variant="contained"
                  loading={this.state.loading} 
                  disabled={this.state.connected}
                  onClick={()=>{this.start()}} 
                  loadingPosition="end"
                  fullWidth>{this.state.infoText}</LoadingButton>
          <Typography sx={{textAlign:'center'}}>
            {this.state.loading?<Clock maxTime={300} message="Esse processo pode demorar até"/>:
             ""
            }     
          </Typography>        
          {this.state.qrCodeData?<img src={this.state.qrCodeData}/>:null}
          </Container>
        )
    }
}




function DeviceInfo(props){
    const data=props.deviceInfo;
    if(!data)
      return null;
    return (
        <React.Fragment>
        <RowContent>
            <Avatar src={data.photo.url||data.photo.base64}/>
            <Typography>{data.name}</Typography>
        </RowContent>
        <RowContent>
            <Typography>{`Numero: ${data.phone}`}</Typography>
        </RowContent>
        </React.Fragment>
        
    )
}

function RowContent(props){
    return (
        <div style={{marginTop:"5px",marginBottom:"5px",display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'row'}}>
           {props.children}
        </div>
    )
}

function getWsUrl(){
    const protocol=window.location.protocol=='https:'?'wss://':'ws://';
    let url=window.location.host;
    if(window.location.hostname=='localhost'){
       url='localhost:3021';
    }
    return (protocol+url+'/server/waconnect');
}


class Clock extends React.Component{
     constructor(props){
        super(props);
        this.state={timeLeft:this.props.maxTime}
     }
     componentDidMount(){
          this.updateClock(this.props.maxTime*1000);
     }
     formatTime(time){
         let minute=Math.floor(time/60);
         let sec=time%60;
         return `${addZero(minute)}min ${addZero(sec)}sec`;
         function addZero(val){
            if(val<10)
               return `0${val}`;
            return val.toString();   
         }
     }
     async updateClock(maxTime=300000){
        if(this.lastClockId)
           clearInterval(this.lastClockId);
        this.clockStart=new Date();
        this.clockMaxTime=maxTime;   
        this.lastClockId=setInterval(()=>{
             let now=new Date();
             let timeLeft=Math.max(0,this.clockMaxTime-(now.getTime()-this.clockStart.getTime()))/1000;
             timeLeft=Math.floor(timeLeft);
             this.setState({timeLeft});   
        },1000)
    }
    componentWillUnmount(){
        if(this.lastClockId)
          clearInterval(this.lastClockId);
    }
     render(){
        return (
            <div>{this.props.message} {this.formatTime(this.state.timeLeft)}</div>
        )
     }
}