import { Button} from "@mui/material"
import { ServerList, updatedServerList } from "../ServerList"
import {EditUser, Register} from "../register"
import { useState } from "react"
import { getNumberWithCountryDDD } from "../../utils/wanumber";

export function AdmUsers(){
   const [register,setRegister]=useState(false); 
   const [user,setUser]=useState(false);
   const header=[
    {id:'first_name',label:'Nome'},
    {id:'email',label:'Email'},
    {id:'phone',label:'Telefone',showFunc:(item)=>item.phone?getNumberWithCountryDDD(item.phone):'Sem Telefone'},
    {id:'user_level',label:'É Adm',disableSearch:true,hidden:true,showFunc:(item)=>{return (item['user_level']=='adm'?'Sim':'Não')}},
    {id:'is_active',label:'Ativo',disableSearch:true,hidden:true,showFunc:(item)=>{return (item['is_active']?'Sim':'Não')}},
    {id:'canUploadFiles',label:'Salvar Arquivos',disableSearch:true,hidden:true,showFunc:(item)=>{return (item['canUploadFiles']?'Sim':'Não')}},
    {id:'last_action',disableSearch:true,label:'Útima ação',showFunc:(item,head)=>formatDate(item[head.id])},
    {id:'see_more',required:true,disableSearch:true,disableSort:true,label:'Ação',showFunc:(item)=>{return <Button onClick={()=>setUser(item)} variant="contained">Ver Mais</Button>}}  
 ]
   return ( <div>
         <div style={{marginTop:'20px',marginBottom:'20px',textAlign:'center'}}>
             <Button variant="contained" onClick={()=>setRegister(true)}>Criar Usuário</Button>
         </div>
        <ServerList gridId='users' processData={(d)=>d.data.users} header={header} url={'/server/adm/userslist'}/>
        <Register open={register} close={()=>setRegister(false)} onRegistered={()=>{updatedServerList();setRegister(false)}}/>
        <EditUser onEdited={updatedServerList} close={()=>setUser(false)} open={Boolean(user)} user={user}/>
    </div>)
}



function formatDate(val){
    const d=new Date(val||0);
    const year=d.getFullYear();
    const month=addZero(d.getMonth()+1);
    const day=addZero(d.getDate());
    const hour=addZero(d.getHours());
    const minutes=addZero(d.getMinutes());
    const seconds=addZero(d.getSeconds());
    return `${day}/${month}/${year} ${hour}:${minutes}:${seconds}`;
    function addZero(number){
      if(number>9)
        return number.toString();
      return `0${number}`;
    }   
}