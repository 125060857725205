import { URLS } from "../env/urls";
import { sendServerRequest } from "../utils/sendreq";
export class ServerWebSocket{
    constructor(url){
       this.ws=new WebSocket(url);
       this.onmessage=[];
    }
    setOnopen(onopen){
        this.ws.onopen=onopen;
    }
    getWS(){
        return this.ws;
    }
    sendPing(){
        this.sendPingId=setInterval(()=>{
            console.log("Ping",this.ws.readyState);
            if(this.ws.readyState==this.ws.CLOSING||this.ws.readyState==this.ws.CLOSED){
              clearInterval(this.sendPingId);
              return;
            }
            this.ws.send(JSON.stringify({action:"ping"}));
        },10000);
    }
    setOnclose(onclose){
        this.ws.onclose=onclose;
    }
    haveOnMsgFn(fn){
        for(let hFn of this.onmessage){
            if(hFn==fn)
              return true;
        }
        return false;
    }
    setOnmessage(onmessage){
        this.onmessage.push(onmessage);
        this.__setOnMessage();
    }
    __setOnMessage(){
        this.ws.onmessage=(m)=>{
            const data=JSON.parse(m.data);
            for(let i=0;i<this.onmessage.length;i++){
                this.onmessage[i](data,m);
            }
        }
    }
    deleteOnMessage(func){
        let newArray=[];
        for(let i=0;i<this.onmessage.length;i++){
            if(this.onmessage[i]!==func){
                newArray.push(this.onmessage[i]);
            }
        }
        this.onmessage=newArray;
    }
    send(data){
        this.ws.send(data);
    }
    setOnerror(onerror){
        this.ws.onerror=onerror;
    }
    close(reason=1000){
        this.ws.close(reason);
    }
    async auth(){
       try{
         const result=await sendServerRequest(URLS.ws.gettoken);
         const data=result.data;
         if(!data.hasError){
             this.token=data.data.token;
             this.userId=data.data.userId;
             this.ws.send(JSON.stringify({action:'auth_ws',token:this.token,userId:this.userId}));
         }
       }catch(e){
           throw e;
       }
    }
    
}


export function getWsUrl(path){
    const protocol=window.location.protocol=='https:'?'wss://':'ws://';
    let url=window.location.host;
    if(window.location.hostname=='localhost'){
       url='localhost:3069';
    }
    return (protocol+url+path);
}