import { sendServerRequest } from "./sendreq";

export async function getNegotiationEnum(){
   try{
       if(!window.negotiationEnums){
          const data=await sendServerRequest('/server/serverinfo/negotiationEnum');
          window.negotiationEnums=data.data.data;
       }
       return window.negotiationEnums;
   }catch(e){
      return {};
   }
}