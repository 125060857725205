import { DialogTitle,Dialog, DialogContent, DialogActions } from "@mui/material";
import { GeralField } from "../field";
import { LoadingButton } from "@mui/lab";
import {IconButton} from "@mui/material";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useEffect, useRef, useState } from "react";
import {  getJustCharsAndNumbers, removeAccentuation, setFirstLetterToUpper } from "../../utils/string";
import {FormControlLabel,Switch} from "@mui/material";
import {Button} from "@mui/material";
import { sendForm } from "../../utils/form";
import { updateSnackStatus } from "../Snack";
import { ServerList, updatedServerList } from "../ServerList";
import {  formatDateWithMin } from "../../utils/date";
import { sendServerRequest } from "../../utils/sendreq";
import { downloadFromUrl, downloadURI } from "../../utils/download";
export function SharedFiles({}){
    const header=[
        {id:'name',label:'Nome'},
        {id:'description',label:'Descrição',
           showFunc:(s)=>{
               const d=s.description;
               if(d.length>30){
                  return `${d.replaceAll('\n',' ').slice(0,27)}...`;
               }
               return d;
           }
        },
        {id:'mime_type',label:'Tipo'},
        {id:'transparency',label:'É Privado',disableSearch:true,showFunc:(s)=>s['transparency']=='private'?'Sim':'Não'},
        {id:'user_email',label:"Email Proprietário",hidden:true},
        {id:'user_name',label:'Nome Proprietário'},
        {id:'updated_at',label:'Atualização',showFunc:(i)=>formatDateWithMin(i.updated_at)},
        {id:'see_more',label:'Ver Mais',isRequired:true,disableSort:true,disableSearch:true,showFunc:(i)=><Button onClick={()=>setFile(i)} variant="contained">Ver Mais</Button>}
    ];
    const [open,setOpen]=useState(false);
    const [file,setFile]=useState(false);
     return (
        <div style={{marginTop:'40px',textAlign:'center'}}>
            {window.userData.canUploadFiles?<Button variant='contained' onClick={()=>setOpen(true)}>Adicionar Arquivo</Button>:null}
            <ServerList gridId='files' processData={(e)=>{return e.data.files}} url={'/server/files/list'} header={header}/>
            <FileUpload close={()=>setOpen(false)} onSended={updatedServerList} open={open}/>
            <FileInfo onChanged={updatedServerList} open={file} close={()=>setFile(false)} data={file}/>
        </div>
     )
}

function checkFileIsPrivate(mode){
   if(mode=='private')
      return true;
    return false;
}

function FileInfo({open,close,data,onChanged}){
    const [editFile,setEditFile]=useState(false);
    const [name,setName]=useState(false);
    const [description,setDescription]=useState(false);
    const [isPrivate,setIsPrivate]=useState(false);
    const [canEdit,setCanEdit]=useState(false);
    const [file,setFile]=useState(false);
    const [editMode,setEditMode]=useState(false);
    const [loading,setLoading]=useState(false);
    const lastData=useRef();
    useEffect(()=>{
       if(!canEdit)
          return;
       if(!name||!description){
            if(editMode)
               setEditMode(false);
            return;
       }
       if(editFile){
           if(!file){
             setEditMode(false); 
           }else{
             setEditMode(true)
           }
           return;
       } 
       if(name!==data.name){
           setEditMode(true);
           return;
       }
       if(description!==data.description){
          setEditMode(true);
          return;
       }
       if(isPrivate!=checkFileIsPrivate(data.transparency)){
          setEditMode(true);
          return;
       }
       setEditMode(false);
    },[editFile,name,description,isPrivate,file]);
    useEffect(()=>{
       if(loading=='delete'){
          sendServerRequest(`/server/files/delete/${data.id}`).then((d)=>{
            updateSnackStatus({
                open:true,
                message:'Arquivo Deletado',
                severity:'success',
             });
             close();
             onChanged();
          }).catch(e=>{
              updateSnackStatus({
                 open:true,
                 message:'Error ao deletar, atualize a página',
                 severity:'error',
              });
              setLoading(false);
          })
       }
       if(loading=='edit'){
            const form=document.getElementById('edit_file_form_id');
            sendForm(`/server/files/edit/${data.id}`,form,(r)=>{
                 const t=r.currentTarget;
                 if(t.readyState==4){
                     if(t.status==200){
                        updateSnackStatus({
                            open:true,
                            message:'Arquivo Editado',
                            severity:'success',
                         });
                         close();
                         onChanged();
                     }else{
                        updateSnackStatus({
                            open:true,
                            message:'Error ao editar, atualize a página',
                            severity:'error',
                         });
                         setLoading(false);
                     }
                 }
            });
       }
    },[loading]);
    useEffect(()=>{
       if(lastData.current!==data){
           setName(data.name);
           setDescription(data.description);
           setIsPrivate(data.transparency=='private'?true:false);
           setEditMode(false);
           setFile(false);
           setEditFile(false);
           setLoading(false);
           if(window.userData.canUploadFiles&&(window.userData.permissionType=='adm'||window.userData.id==data.user_id)){
               setCanEdit(true);
           }else{
                setCanEdit(false);
           }
           lastData.current=data;
       }
    });
    if(!data)
           return null;
    const handleClose=()=>{
        if(loading)
           return;
        close();
    }    
    return (
        
        <Dialog open={open} onClose={handleClose}>
           <DialogTitle>{`Nome: ${data.name}.${data.mime_type}`}</DialogTitle>

           <DialogContent>
                <div>
                    <p>{`Proprietário: ${data.user_name}`}</p>
                    <p>{`Email Proprietário: ${data.user_email}`}</p>
                    <p>{`Última atualização: ${formatDateWithMin(data.updated_at)}`}</p>
                </div>
                <form  id="edit_file_form_id" encType="multipart/form-data">
                  <GeralField fullWidth name='name' onChange={setName} label={'Nome'} defaultValue={name} readOnly={!canEdit} editable={canEdit} filters={[removeAccentuation,(s)=>getJustCharsAndNumbers(s,true)]}/>
                  <GeralField fullWidth name='description' onChange={setDescription} multiline label={'Descrição'} readOnly={!canEdit} editable={canEdit} value={description} filters={[setFirstLetterToUpper]}/>
                <div style={{textAlign:'center'}}>
                  <FormControlLabel disabled={!canEdit} control={<Switch  name={"is_private"} onChange={(e)=>setIsPrivate(e.target.checked)}  checked={isPrivate}/>} label='Arquivo Privado'/>
                </div>
                {canEdit?
                  <div style={{marginTop:'30px'}}>
                       <div style={{textAlign:'center'}}>
                         <FormControlLabel disabled={!canEdit} control={<Switch onChange={(e)=>setEditFile(e.target.checked)}  checked={editFile}/>} label='Editar Arquivo'/>
                       </div>
                       {editFile?
                         <div style={{textAlign:'center'}}>
                           <FileField value={file} onChangeValue={(e)=>setFile(e.target.value)}/>
                         </div>
                       :null}
                       <LoadingButton onClick={editFileFunc} disabled={!editMode} loading={loading} fullWidth variant="contained">Salvar</LoadingButton>
                       <LoadingButton fullWidth onClick={deleteFile}  loading={loading} sx={{color:'red'}}>Deletar Arquivo</LoadingButton>
                  </div>
                :null}
                </form>
           </DialogContent>
           <DialogActions>
               <Button disabled={loading} onClick={()=>downloadFromUrl(`/server/files/download/${data.id}/${data.path}`,`${data.name}.${data.mime_type}`)} variant="contained">Download</Button>
               <Button disabled={Boolean(loading)} onClick={handleClose}>Sair</Button>
           </DialogActions>
        </Dialog>
    )
    function deleteFile(){
        const cont=window.confirm("Tem certeza que deseja DELETAR o arquivo? Essa ação é irreversivel!");
        if(!cont){
            return;
        }
        setLoading("delete");
    }
    function editFileFunc(){
        setLoading('edit');
    }
}
function FileUpload({open,close,onSended}){
    const [fileValue,setFileValue]=useState("");
    const [name,setName]=useState('');
    const [description,setDescription]=useState('');
    const [isPrivate,setPrivate]=useState(true);
    const [loading,setLoading]=useState(false);
    
    useEffect(()=>{
        if(loading=="upload"){
            upload();
        }
        if(loading=='clear'){
            setLoading(false);
            setName("");
            setDescription("");
            setFileValue("");
        }
    });
    useEffect(()=>{
        if(!fileValue){
            setName("");
            return;
        }
        const t=fileValue.split('\\');
        setName(getJustCharsAndNumbers(removeAccentuation(t[t.length-1].split('.')[0]).replaceAll('_',' '),true));
    },[fileValue])
    const handleClose=()=>{
        if(loading)
           return;
        close();
    }
    return (
        <Dialog open={open} onClose={handleClose}>
             <DialogTitle>Upload De Arquivo</DialogTitle>
             <DialogContent>
             <form  id="upload_file_form_id" encType="multipart/form-data">
                <div  style={{textAlign:"center"}}>
                <div>
               <FileField value={fileValue} onChangeValue={(e)=>setFileValue(e.target.value)}/>
               </div>
               {fileValue?<div><GeralField helperText={"Só é aceito letras e números."} value={name} onChange={setName} fullWidth name="name" label="Nome" filters={[removeAccentuation,(s)=>getJustCharsAndNumbers(s,true)]}/>
               </div>:null}
               <GeralField helperText={"Descreva o arquivo."} value={description}  onChange={setDescription} required multiline fullWidth  filters={[setFirstLetterToUpper]} name="description" label="Descrição"/>
               <FormControlLabel control={<Switch onChange={(e)=>{setPrivate(e.target.checked)}} name={"is_private"}  checked={isPrivate}/>} label='Arquivo Privado.'/>
               <LoadingButton onClick={()=>setLoading("upload")} disabled={!fileValue||!name||!description} loading={Boolean(loading)} fullWidth variant="contained">Enviar</LoadingButton>
               </div>
            </form>
             </DialogContent>
             <DialogActions>
                <Button variant='contained' disabled={Boolean(loading)} onClick={handleClose}>Fechar</Button>
             </DialogActions>
        </Dialog>
    )
    function upload(){
             const form=document.getElementById("upload_file_form_id");
             sendForm('/server/files/upload',form,(r)=>{
                 const c=r.currentTarget;
                 if(c.readyState==4){
                    if(c.status==200){
                        updateSnackStatus({
                            open:true,
                            message:'Arquivo enviado.',
                            severity:'success',
                        });
                        setLoading('clear');
                        onSended();
                    }else{
                        updateSnackStatus({
                            open:true,
                            message:'Erro, atualize a página.',
                            severity:'error',
                        });
                        setLoading(false);
                    }
                    
                 }
             });
    }
}

function FileField({value,onChangeValue}){
    return (
               <label htmlFor="icon-button-file">
                    <input onChange={onChangeValue} value={value||''} style={{display:"none"}} name="file" required accept="*" id="icon-button-file" type="file" />
                    <IconButton color={value?"success":"primary"} aria-label="upload audio" component="span">
                        <UploadFileIcon sx={{fontSize:"40px"}} />
                    </IconButton>
                    <div style={{textAlign:'center'}}>
                           <p>{fileName()}</p>
                    </div>
                </label>
    )
    function fileName(){
       if(!value)
         return "Clique no ícone";
       const t=value.split('\\');
       return t[t.length-1]; 
    }
}