export const URLS={
    session:{
        host:'/server/session/host',
        destroy:'/server/session/destroy',
    },
    
    user:{
      info:"/server/userinfo/get",  
      get:"/server/user/getuser",
      login:"/server/user/login",
      logout:"/server/user/logout",
      register:'/server/adm/createDealer'
    },

    
    ws:{
        gettoken:"/server/wsauth/gettoken",
    },
    whatsapp:{
        gethostinfo:"/server/whatsapp/gethostinfo",
        disconnect:"/server/whatsapp/disconnect",
        leads:{
            list:"/server/whatsapp/leads/list",
            leech:"/server/whatsapp/leads/leech",
            supportReasons:"/server/whatsapp/leads/supportReasons",
        },
        db:{
            list:"/server/whatsapp/db/list",
         },
        text:{
            list:"/server/text/list",
            register:"/server/text/register",
            alter:"/server/text/alter",

        },
        shots:{
            create:"/server/shots/create",
            list:"/server/shots/list",
            info:"/server/shots/info",
            active:'/server/shots/active',
            deactive:'/server/shots/deactive',
            reset:'/server/shots/reset',
            delete:'/server/shots/delete'
        },
        
    },
    functions:{
        chatBot:{
            unlink:"/server/chatbot/unlink"
        },
        archChatBot:{
            list:"/server/archchatbot/list",
            get:"/server/archchatbot/get",
            active:"/server/archchatbot/active",
            crete:"/server/archchatbot/create",
            update:"/server/archchatbot/update",
        }
    },
    audio:{
        upaudio:"/server/audio/upload",
        getlist:"/server/audio/list",
        delete:"/server/audio/delete",
        getAudio:"/server/audio/get"
    },

};