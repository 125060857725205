export function serverDateStringToDate(str){
    let val=str.split('-');
    if(val.length<3)
      throw "Must be yyyy-mm-dd";
    return new Date(val[0],val[1]-1,val[2],0,0,0,0);
}

export function formatDate(val){
  if(!val)
    return "";
  const d=new Date(val);
  const year=d.getFullYear();
  const month=d.getMonth()+1;
  const day=d.getDate();
  return `${addZero(day)}/${addZero(month)}/${year}`;
  function addZero(number){
    if(number>9)
      return number.toString();
    return `0${number}`;
  }   
}
export function formatDateWithMin(val){
  const d=new Date(val||0);
  const year=d.getFullYear();
  const month=addZero(d.getMonth()+1);
  const day=addZero(d.getDate());
  const hour=addZero(d.getHours());
  const minutes=addZero(d.getMinutes());
 // const seconds=addZero(d.getSeconds());
  return `${day}/${month}/${year} ${hour}:${minutes}`;
  function addZero(number){
    if(number>9)
      return number.toString();
    return `0${number}`;
  }   
}