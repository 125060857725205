import { MobileDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { IconButton, TextField } from "@mui/material";
import ptBR from 'date-fns/locale/pt-BR';
import React, { useState } from 'react';
import { Autocomplete } from '@mui/material';
import { getJustChars, getJustCharsAndNumbers, setAnyFirstLetterToUpper } from '../utils/string';
import { CircularProgress } from '@mui/material';
import { createFilterOptions } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import {InputAdornment} from "@mui/material";
import {MobileDateTimePicker} from '@mui/lab';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
class InputBase extends React.Component{
    constructor(props){
        super(props);
        this.lastForceRestartNumber=this.props.forcerestartnumber;
    }
    mustRestart(){
        let lastForceRestartNumber=this.lastForceRestartNumber;
        this.lastForceRestartNumber=this.props.forcerestartnumber;
        return (this.props.forcerestartnumber!==lastForceRestartNumber);
    }
    componentDidUpdate(){
        if(this.mustRestart()){
            this.setState(this.initalState);
        }
    }

}
export class NumberField extends InputBase{
    constructor(props){
        super(props);
        this.state={value:this.props.defaultValue||""};
        this.initalState={};
        Object.assign(this.initalState,this.state);
    }
    onChange(e){
        let value=e.target.value;
        if(this.props.min){
            value=Math.max(value,this.props.min);
        }
        if(this.props.max){
            value=Math.min(this.props.max,value);
        }
        this.setState({value:value});
        this.props.onChange&&this.props.onChange(value);
    }

    render(){
        return (
            <TextField {...this.props} value={this.state.value} onChange={(e)=>{this.onChange(e)}} type="number"/>
        )
    }

}
export class UpperCaseTextField extends InputBase{
    
    constructor(props){
        super(props);
        this.state={value:this.props.startvalue?this.props.startvalue:""};
        this.initalState={};
        Object.assign(this.initalState,this.state);
    }

    onChange(e){
       let value=setAnyFirstLetterToUpper(e.target.value);
       if(this.props.justCharsAndNumber)
         value=getJustCharsAndNumbers(value,true);
       this.setState({value:value});
       if(this.props.getonchange){
         this.props.getonchange(value);
       }
    }
    render(){
        return (
            <TextField {...this.props} onChange={(e)=>{this.onChange(e)}} value={this.state.value}/>
        ) 
    }
}
export function MyDateTimePicker(props){
    let [date,setDate]=React.useState(props.defaultValue?typeof(props.defaultValue)=='string'?new Date(props.defaultValue):props.defaultValue:new Date());
    const [editMode,setEditMode]=useState(false);
    const editable=props.editable;
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
            <MobileDateTimePicker  
                    maxDate={props.maxDate}
                    minDate={props.minDate}
                    placeHolder="dd/mm/yyyy hh:mm" 
                    label={props.label} 
                    openTo="year"
                    disabled={editable&&!editMode}
                    views={['year', 'month', 'day','hours','minutes']}
                    renderInput={(params) => <TextField margin='normal' name={props.name} fullWidth {...params} InputProps={{
                        endAdornment:editable?(<InputAdornment position="end"> 
                        <IconButton onClick={()=>{setEditMode(!editMode)}}>
                          {editMode?<CheckIcon/>:<EditIcon/>}
                        </IconButton>
                       </InputAdornment>):null,
                       
                      }}/>}
                    value={date}
                    onChange={(date)=>{setDate(date);props.onChange&&props.onChange(date)}}
                    />
        </LocalizationProvider>        
    )
}

export function MyDatePicker(props){
    let [date,setDate]=React.useState(props.defaultValue?typeof(props.defaultValue)=='string'?new Date(props.defaultValue):props.defaultValue:new Date());
    const [editMode,setEditMode]=useState(false);
    const editable=props.editable;
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
            <MobileDatePicker  
                    maxDate={props.maxDate}
                    minDate={props.minDate}
                    placeHolder="dd/mm/yyyy" 
                    label={props.label} 
                    openTo="year"
                    disabled={editable&&!editMode}
                    views={['year', 'month', 'day']}
                    renderInput={(params) => <TextField margin='normal' name={props.name} fullWidth {...params} InputProps={{
                        endAdornment:editable?(<InputAdornment position="end"> 
                        <IconButton onClick={()=>{setEditMode(!editMode)}}>
                          {editMode?<CheckIcon/>:<EditIcon/>}
                        </IconButton>
                       </InputAdornment>):null,
                       
                      }}/>}
                    value={date}
                    onChange={(date)=>{setDate(date);props.onChange&&props.onChange(date)}}
                    />
        </LocalizationProvider>        
    )
}

export function ClockInput({defaultValue,name,label,onChangeClock,minTime}){
    let [date,setDate]=React.useState(defaultValue?defaultValue:new Date());
    return(
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
            <MobileTimePicker
               onChange={(date)=>{onChangeClock&&onChangeClock(date);setDate(date)}}
               value={date}
               label={label}
               minTime={minTime}
               renderInput={(params) => <TextField name={name} required fullWidth {...params} />}
            />
        </LocalizationProvider>
    )
}

export class PhoneTextField extends InputBase{
    constructor(props){
        super(props);
        this.state={color:'primary',
                    value:this.formatShowValue(this.props.defaultValue?this.props.defaultValue:"").formatted};
        this.initalState={};
        Object.assign(this.initalState,this.state);
    }
    onChange(elm){

        let values=this.formatShowValue(elm.target.value);
        let color='primary';
        const isValid=this.checkIsValid(values.justNumbers)
        isValid?color='success':color='error';
        this.setState({color:color,value:values.formatted});
        if(this.props.onFormatOK){
            this.props.onFormatOK({value:values.justNumbers,formattedValue:values.formatted,isValid:isValid})
        }
        this.props.onChangePhone&&this.props.onChangePhone({value:values.justNumbers,formattedValue:values.formatted,isValid:isValid});
    }
    formatShowValue(value){
        let justNumbers=getJustNumbers(value,14);
        let formatted=formatNumbers({0:'(',2:')'},justNumbers);
        return {
            justNumbers,
            formatted,
        }
    }
    checkIsValid(value){
        if(value.length>8)
          return true;
        return false;  
    }
    render(){
        return(
            <TextField
               type="tel"
               color={this.state.color}
               value={this.state.value}
               onChange={(e)=>{this.onChange(e);}}
               {...this.props}
            />
        )
    }
}
export class RGTextField extends InputBase{
    constructor(props){
        super(props);
        this.state={color:'primary',value:""};
        this.initalState={};
        Object.assign(this.initalState,this.state);
    }
    onChange(elm){
        let value=getJustNumbers(elm.target.value,18);
        let color='primary';
        this.checkIsValid(value)?color='success':color='error';
        this.setState({color:color,value:value});
    }

    checkIsValid(value){
        if(value.length>5)
          return true;
        return false;  
    }
    render(){
            return(
                <TextField  
                    color={this.state.color}
                    onChange={(e)=>{this.onChange(e)}}
                    value={this.state.value}
                 {...this.props}/>
            )
    }
}

export class CPFTextField extends InputBase{
    constructor(props){
        super(props);
        this.initalState={};
        this.state={color:"error",value:""};
        Object.assign(this.initalState,this.state);
        this.value="";
    }
    onChange(e){
      let value=getJustNumbers(e.target.value,14);
      let formattedValue=formatNumbers({3:'.',6:'.',9:'-'},value);
      let color='primary';   
      if(this.cpfIsValid(value)){
         color='success';
      }else{
        color='error';  
      }   
      this.setState({color:color,value:formattedValue});      
    }

    cpfIsValid(cpf){
       if(cpf.length<11)
         return false;
       if(!checkDigit(10,9))  
          return false;
       if(!checkDigit(11,10))
          return false;
       return true; 

       function checkDigit(base,max){
          let sum=0;
          for(let i=0;i<max;i++){
              sum+=(parseInt(cpf[i])*(base-i));
          }
          const rest=sum%11;
          const digit=rest<2?0:11-rest;
          if(parseInt(cpf[max])!==digit)
             return false;
          return true;       
       }
    }
    render(){
        return(
            <TextField 
               color={this.state.color}
               value={this.state.value}
               onChange={(e)=>{this.onChange(e);}}
               {...this.props}/>
        );
    }
}




export function EmailTextField(props){
    return (
        <TextField type="email" {...props}/>
    )
}

export function getJustNumbers(cvalue,max=-1){
    let value=cvalue.toString();
    let filteredValue='';
    let maxLength=max>0?max:value.length;
    for(let i=0;i<Math.min(value.length,maxLength);i++){
        let asc2=value.charCodeAt(i); 
        if(asc2>47 && asc2<58){
             filteredValue+=value.charAt(i);              
         }   
   }
   return filteredValue;
}

export function formatNumbers(points={},value){
    let showValue="";
    for(let i=0;i<value.length;i++){
        if(points[i])
        showValue+=points[i];
        showValue+=value[i];              
    }
    return showValue;
}
export function getFloat(data){
    let i=0;
    for(;i<data.length;i++){
       let asc2=data.charCodeAt(i);   
       if(asc2>47 && asc2<58)
          break;
    }
    return parseFloat(data.slice(i,data.length));       
}
export function numberToCurrencyString(number,currency,digits){
    if(number==undefined || number==null)
          return null;
    let value=typeof(number)==='string'?getJustNumbers(number):number;
    let fValue=typeof(number)==='string'?value/Math.pow(10,digits):value;
    let showValue=fValue.toFixed(digits);
    showValue=showValue.replace('.',',');
    return `${currency} ${showValue}`;
}

export function CurrencyField(props){
    const currency="R$";
    const digits=2;
    function onChange(e){
        let value=numberToCurrencyString(e.target.value,currency,digits);
        e.target.value=value;
    }

    return (
        <TextField onChange={onChange} defaultValue={numberToCurrencyString(props.default,currency,digits)} {...props}/>
    )
}


export function AutocompleteInputRender(props){
    let CustomField=props.CustomField||TextField;
    return(
        <React.Fragment>
         <CustomField
          {...props}
          isupdating={null}
          InputProps={{
            ...props.InputProps,
            endAdornment: (
              <React.Fragment>
                {props.isupdating ? <CircularProgress color="inherit" size={20} /> : null}
                {props.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
        />
         <input type="hidden" name={props.inputname} value={props.inputvalue}/>
        </React.Fragment>
    )
}


export class AutocompleteSelect extends React.Component{
    constructor(props){
        super(props);
        this.state={value:''};
    }
    onChange(e,v){
        if(!v)
          return
        let value=""; 
        if(Array.isArray(v)){
            let values=v.map((value)=>{return value.id||value.inputValue||value.label||value});
            value=values.join(this.props.multipleSeparator||" ");
        }else{
            value=v.id||v.inputValue||v.label||v;
        }
        if(this.props.onChange){
            this.props.onChange(value,e,v);
        }
        this.setState({value:value});
    }
    componentDidMount(){

    }
    filterOptions(options,params){
       let filtered=createFilterOptions()(options,params);
       let inputFiltered=this.props.formatFreeSolo(params.inputValue);
       if(filtered.length<1){
          filtered.push({label:`Adicionar ${inputFiltered}`,inputValue:inputFiltered})
       }
       return filtered;
    }
    render(){
        return (
            <Autocomplete
               freeSolo={this.props.freeSolo||false}
               multiple={this.props.multiple||false}
               required={this.props.required}
               loading={this.props.isUpdating}
               defaultValue={this.props.defaultValue}
               filterOptions={this.props.formatFreeSolo?this.filterOptions.bind(this):undefined}
               getOptionLabel={(option)=>{
                  if(typeof(option)=='string')
                    return option;
                  if(option.inputValue)
                    return option.inputValue;
                  return option.label;    
               }}
               disabled={this.props.disabled}
               renderOption={(props, option) => <li {...props}>{option.label||option}</li>}
               loadingText={this.props.loadingText?this.props.loadingText:"Carregando..."}
               noOptionsText={this.props.noOptionsText?this.props.noOptionsText:"Sem opções"}
               options={this.props.options}
               onChange={(e,v)=>{this.onChange(e,v)}}
               onInputChange={(v)=>this.props.onInputChange&&this.props.onInputChange(v)}
               renderInput={(p)=><AutocompleteInputRender required={this.props.required} isupdating={this.props.isUpdating} {...p} inputname={this.props.name} inputvalue={this.state.value} label={this.props.label}/>}
               fullWidth
            />   
        )
    }
}


export class PasswordTextField extends React.Component{
    constructor(props){
        super(props);
        this.state={type:'password'}
    }
    change(){
        if(this.state.type=='password'){
            this.setState({type:'text'});
        }else{
            this.setState({type:'password'});
        }
    }
    endAdornment(){
        return (
            <IconButton onClick={()=>this.change()}>{this.state.type=='password'?<Visibility/>:<VisibilityOff/>}</IconButton>
        )
    }
    render(){
        let props=this.props;
        return (
            <TextField type={this.state.type} {...props} InputProps={{
                                                endAdornment:this.endAdornment(),
                                              }}
            />
        )
    }
}