import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import {IconButton, TextField } from '@mui/material';
import { Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import { Select } from '@mui/material';
import { MenuItem } from '@mui/material';
import { Checkbox } from '@mui/material';
import { ListItemText } from '@mui/material';
import TableSortLabel from '@mui/material/TableSortLabel';
import { LinearProgress } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
export class DataGrid extends React.Component{
    constructor(props){
        super(props);
        this.state={selectedPage:this.props.seletectedpage?this.props.seletectedpage:0,
                    itemsPerPage:this.props.itemsPerPage?this.props.itemsPerPage:this.getDefaultItemsPerPage(),
                    searchFieldId:this.getFirstSelectedHeaderId(),
                    searchFieldValue:'',
                    orderBy:'desc',
                    orderFieldId:this.props.orderFieldId||'',
                    items:Array.isArray(this.props.items)?this.props.items:[],
                    itemsLength:this.props.items?this.props.items.length:0,
                    showedColumns:this.getShowedColumns(),
                    filters:this.props.filters,
                    lastHeader:this.props.header,
                   };                      
    }
    getLocalStorageNameItemsPerPage(){
      return `grid_${this.props.gridId}_itemsperpage`;
    }
    getLocalStorageNameFields(){
      return `grid_${this.props.gridId}_columns`;
    }
    getDefaultItemsPerPage(){
        if(!this.props.gridId)
           return 5;
        const data=window.localStorage.getItem(this.getLocalStorageNameItemsPerPage());  
        if(!data)
          return 5;
        let result=parseInt(data);
        if(isNaN(result))
           return 5
        return result;
    }
    componentDidUpdate(){
         if(this.props.header!==this.state.lastHeader){
          this.setState({
            showedColumns:this.getShowedColumns(),
            lastHeader:this.props.header,
           },()=>{
            this.updatedItems();
           })
         }
         this.updatedItems();
         
    }

    getShowedColumns(){
      const oldShowed=this.getShowedColumnsStoredData();
      let showedColumns=[];
      const header=this.props.header;
      for(let i=0;i<header.length;i++){
        if(!header[i].hidden)
           header[i].hidden=false;
        showedColumns.push(header[i]);  
      }
      if(Array.isArray(oldShowed))
        for(let i=0;i<showedColumns.length;i++){
          const id=showedColumns[i].id;
          for(let old of oldShowed){
              if(old.id==id){
                showedColumns[i].hidden=old.hidden;
              }
          }
        }
      return showedColumns;
    }
    componentDidMount(){
        this.updatedItems();
    }
    onChangeShowedColumns(item){
        let showedColumns=[];
        const oldShowedColumns=this.state.showedColumns;
        for(let i=0;i<oldShowedColumns.length;i++){
            if(item.id==oldShowedColumns[i].id){
              oldShowedColumns[i].hidden=!oldShowedColumns[i].hidden;
            }
            showedColumns.push(oldShowedColumns[i]);
        }
        updateShowedStore.bind(this)(showedColumns);
        this.setState({showedColumns:showedColumns});  
        function updateShowedStore(store){
            if(!this.props.gridId)
               return;
            window.localStorage.setItem(this.getLocalStorageNameFields(),JSON.stringify(store));      
        }
    }
    getShowedColumnsStoredData(){
         if(!this.props.gridId)
           return [];
         const data=window.localStorage.getItem(this.getLocalStorageNameFields());
         if(!data)
            return [];
        let retData=[];
        try{
             retData=JSON.parse(data);
             if(!Array.isArray){ 
                retData=[];
             }
        }catch(e){

        }
        return retData;
    }

    
    onChangeFilter(filter){
       let filters=this.state.filters;
       for(let i=0;i<filters.length;i++){
          if(filter.label!==filters[i].label)
            continue;
          filters[i].isActive=!filters[i].isActive;
       }
       this.setState({filters:filters});
    }
    onSearchIdChange(e){
        if(this.state.searchFieldId!=e.target.value)
          this.setState({searchFieldId:e.target.value});
    }
    onSearchValueChange(e){
       if(this.state.searchFieldValue!=e.target.value){
           this.setState({searchFieldValue:e.target.value,selectedPage:0});
       }
    }
    onClickShort(e){
        const isAsc=(this.state.orderFieldId===e && this.state.orderBy==='desc');
        const orderBy=isAsc?'asc':'desc';
        this.setState({orderFieldId:e,orderBy:orderBy});
    }
    getHead(header){
      return header.map((e)=>{
         if(e.hidden)
           return null;
         return (<TableCell align="center" key={e.id.toString()}>
                      <TableSortLabel
                         disabled={e.disableSort}
                         active={this.state.orderFieldId===e.id}
                         direction={this.state.orderFieldId===e.id?this.state.orderBy:'desc'}
                         onClick={(k)=>{this.onClickShort(e.id);}}
                      >
                      {e.label}
                      </TableSortLabel>
                      </TableCell>)
      });  
    }
    sortItems(){
        if(!Boolean(this.state.orderFieldId))
          return;
        let items=this.getItems();  
        const compare=this.state.orderBy==='asc'?lower:greater;
        const param=this.state.orderFieldId;
        let sortItems=[];
        
        while(items.length>0){
            let extremeIndex=findExtremityIndex(compare);
            sortItems.push(items[extremeIndex]);
            let tempItems=items.map((e)=>{return e});
            items=[];
            let setIndex=0;
            for(let i=0;i<tempItems.length;i++){
                if(i==extremeIndex)
                  continue;
                items[setIndex]=tempItems[i];
                setIndex++;
            }     
        }
        this.setItems(sortItems);
        function findExtremityIndex(compare){
          let extremeIndex=0;
          for(let i=0;i<items.length;i++){
              if(compare(items[i][param],items[extremeIndex][param]))
                extremeIndex=i;
          }
          return extremeIndex;
        }
        function greater(a,b){
            if(a>b)
              return true;
            return false;  
        }
        function lower(a,b){
            if(a<b)
              return true;
            return false;
        }
    }
    setItems(items){
       this.__items=items;
    }
    getItems(){
      let items;
      items=Array.isArray(this.__items)?(this.__items.map(e=>{return e})):[];  
      return items;
    }
    updatedItems(){
        if(this.isUpdated){
            this.isUpdated=false;
            return;
        }        
        this.setItems(this.props.items.concat(Array.isArray(this.props.pushItems)?this.props.pushItems:[]));
        this.filterItems();
        this.searchItems();
        this.sortItems();
        this.setShowedItems();
    }
    filterItems(){
       if(!this.state.filters)
         return;
       const filters=this.state.filters;
       for(let i=0;i<filters.length;i++){
          if(!filters[i].isActive)
            continue;
          filter.bind(this,filters[i].func)();  
       }  
       function filter(func){
          let gitems=this.getItems();
          let items=[];
          for(let i=0;i<gitems.length;i++){
            if(!func(gitems[i]))
              continue;
            items.push(gitems[i]);
          }
          this.setItems(items);
       }
    }
    searchItems(){
        if(!Boolean(this.state.searchFieldValue))
           return;   
        let gItems=this.getItems();  
        let items=[];
        const findStr=this.state.searchFieldValue.toUpperCase();
        for(let i=0;i<gItems.length;i++){
           let item=gItems[i];
           if(item[this.state.searchFieldId]?.toString().toUpperCase().search(findStr)<0)
              continue;
           items.push(gItems[i]);       
        }
        this.setItems(items);
    }
    setShowedItems(){
        const items=this.getItems();
        let totalFilteredItems=[];
        totalFilteredItems=totalFilteredItems.concat(items);
        let maxPage=parseInt(items.length/this.state.itemsPerPage);
        const selectedPage=Math.min(this.state.selectedPage,maxPage);
        const startIndex=this.state.itemsPerPage*selectedPage;
        const endIndex=this.state.itemsPerPage*(selectedPage+1);         
        let showItems=[];
        for(let i=Math.min(startIndex,items.length);i<Math.min(endIndex,items.length);i++){
           showItems.push(items[i]);
        }
        this.isUpdated=true;
        
        this.setState({items:showItems,
                       filteredItems:totalFilteredItems,
                       itemsLength:items.length,
                       selectedPage:selectedPage,
                     });
    }
    getBody(header,items){
        if(!Boolean(items))
          return null;
        return items.map((item,index)=>{
          return (<TableRow key={`row-item-${index}`}>
               {header.map((head)=>{
                  if(head.hidden)
                    return null;
                  return (<TableCell key={`item-${index}-${head.id}`} align='center'>
                              {head.showFunc?head.showFunc(item,head,index):item[head.id]}
                              </TableCell>);    
               })}
          </TableRow>);
        });  
    }
    onRowsPerPageChange(e){
        if(this.state.itemsPerPage!=e.target.value){
           let newState={itemsPerPage:e.target.value};
           const selectedPage=parseInt(this.state.itemsPerPage*this.state.selectedPage/e.target.value);
           if(selectedPage!=this.state.selectedPage)
             Object.assign(newState,{selectedPage:selectedPage});
           this.setState(newState);
           if(this.props.gridId){
               window.localStorage.setItem(this.getLocalStorageNameItemsPerPage(),e.target.value.toString());
           }
        }
    }
    onPageChange(e,page){
       if(this.state.selectedPage!=page){
          this.setState({selectedPage:page});
       }  
    }
    getFirstSelectedHeaderId(){
        let header=this.props.header;
        for(let i=0;i<header.length;i++){
            if(header[i].selected)
              return header[i].id;
        }
        return header[0].id;
    }
    downloadCSV(){
        let csv = this.props.csvConversor(this.state.filteredItems,this.state.showedColumns);
        var downloadLink = document.createElement("a");
        var blob = new Blob(["\ufeff", csv]);
        var url = URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = `negocios_relatorio_${(new Date()).getTime().toString()}.csv`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }
    render(){
        return(
            <div style={{marginTop:"25px"}}>
            <div style={{display:"flex",justifyContent:"center",textAlign:"center",alignItems:'center',flexFlow:'wrap'}}> 
                <SearchFieldWithSelect header={this.props.header} 
                                       selected={this.getFirstSelectedHeaderId()} 
                                       onChangeSelected={(e)=>{this.onSearchIdChange(e);}}
                                       onChangeInput={(e)=>{this.onSearchValueChange(e);}}
                                       />
                <FilterShowedColumns onClickItem={this.onChangeShowedColumns.bind(this)} items={this.props.header} showed={this.state.showedColumns}/>                  
                <PersonalizedFilters filters={this.state.filters} onClickItem={this.onChangeFilter.bind(this)}/>
                {this.props.csvConversor?<IconButton onClick={()=>this.downloadCSV()} size='large' color='info'><FileDownloadIcon/></IconButton>:null}
            </div>
           <Paper >
            <TableContainer>
            <Table
                 sx={{minWidth:'400px'}}
              >
             <TableHead>
                 <TableRow>
                    {this.getHead(this.state.showedColumns)}
                </TableRow>
             </TableHead>
             <TableBody >
                 {this.getBody(this.state.showedColumns,this.state.items)}
             </TableBody>
        </Table>
        </TableContainer>
        <div style={{marginTop:'10px',display:'flex'}} >          
           <TablePagination labelRowsPerPage="Linhas por paginas" 
                            count={this.state.itemsLength} 
                            page={Math.min(this.state.selectedPage)} 
                            rowsPerPage={this.state.itemsPerPage} 
                            onRowsPerPageChange={(e)=>{this.onRowsPerPageChange(e);}}
                            onPageChange={(e,page)=>{this.onPageChange(e,page);}}
                            rowsPerPageOptions={[5,10,25,50,100]}
                            sx={{margin:'auto'}}/>
        </div>
        {this.props.isUpdating?<LinearProgress color="primary" />:null}  
        </Paper>
        </div>
        )
    }

}


function SearchFieldWithSelect(props){
   let [selected,setSelected]=React.useState(props.selected?props.selected:"");
   function onChangeSelected(e){
       setSelected(e.target.value);
       if(props.onChangeSelected)
         props.onChangeSelected(e);
   }
   function onChangeInput(e){
       if(props.onChangeInput)
        props.onChangeInput(e);
   }
   return(
    <Paper>    
        <Select
             onChange={onChangeSelected}
             value={selected}
          >
            {props.header.map(e=>{
                if(e.disableSearch)
                  return null;
                return (<MenuItem key={e.id} value={e.id}>{e.label}</MenuItem>)
            })}
        </Select>
        <TextField type="search"
                InputProps={
                    {startAdornment:(
                            <InputAdornment position="start">
                            <SearchIcon />
                            </InputAdornment>
                        ),}
                }
                onChange={onChangeInput}

        />  
    </Paper> 
   )
}

class FilterShowedColumns extends React.Component{
    constructor(props){
      super(props);
    }
    onClick(item){
        this.props.onClickItem(item);
    }
    render(){
      return (
        <Paper>
          <Select
             multiple
             displayEmpty
             value={[]}
             renderValue={()=>(<em>Colunas</em>)}
             autoWidth
          >
            {
              this.props.items.map((item)=>{
                 if(item.isRequired)
                   return null;
                 return (
                   <MenuItem key={item.id}  onClick={()=>{this.onClick(item)}}>
                      <ListItemText primary={item.label}/>
                      <Checkbox checked={!item.hidden}/>
                   </MenuItem>
                 )
              })
            }
          </Select>
        </Paper>
      )
    }
}


class PersonalizedFilters extends React.Component{
  constructor(props){
    super(props);
  }
  onClick(filter){
      this.props.onClickItem(filter);
  }
  render(){
    if(!this.props.filters)
         return null;   
    return (
      <Paper>
        <Select
           multiple
           displayEmpty
           value={[]}
           renderValue={()=>(<em>Filtros</em>)}
           autoWidth
        >
          {
            this.props.filters.map((filter)=>{
               return (
                 <MenuItem key={filter.label}  onClick={()=>{this.onClick(filter)}}>
                    <ListItemText primary={filter.label}/>
                    <Checkbox checked={!filter.isActive?false:true}/>
                 </MenuItem>
               )
            })
          }
        </Select>
      </Paper>
    )
  }
}
