export function changeLocationPathName(pathName){
    if(pathName==undefined)
      return;
    window.history.pushState({},'',window.location.origin+pathName);
    let event=new Event('onurlupdate');
    window.dispatchEvent(event);
}

export function searchLastIndex(str,find){
   if(!Boolean(find) || typeof(find)!=='string')
     return -1;
   let lastIndex=str.search(find);
   let index=lastIndex;
   while(index>-1){
     index=str.slice(lastIndex+1).search(find);
     if(index>-1)
       lastIndex+=index+1;
   }
   return lastIndex;   
}

export function separateLastBar(str){
  if(!Boolean(str) || typeof(str)!=='string')
    return ['',''];
  let lastIndex=searchLastIndex(str,'/');
  if(lastIndex<0)
    return [str,''];
  return [str.slice(0,lastIndex),str.slice(lastIndex)];  
}