import React from 'react';
import {ResponsivelLayout} from "./MyComponents/MyResponsiveLayoutBase";
import { Backdrop } from '@mui/material';
import { CircularProgress } from '@mui/material';
import SignIn from './MyComponents/Login';
import {userIsLogged,logOut, userIsAdm} from './utils/user'
import { changeLocationPathName } from './utils/location';
import LogoutIcon from '@mui/icons-material/Logout';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { Snack } from './MyComponents/Snack';
import { checkVersion } from './version';
import { Negotiation, NegotiationOrSchedule } from './MyComponents/register/negotiation';
import { AdmUsers } from './MyComponents/adm/users';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { SharedFiles } from './MyComponents/files/files';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import ShareIcon from '@mui/icons-material/Share';
import CloudIcon from '@mui/icons-material/Cloud';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { CURRENT_VERSION,formatVersion } from './version';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import { WhatsAppConnection } from './Whatsapp/connection';
const listItems=[
    {
        type:'accordion',
        summary:'Registros',
        icon:<AppRegistrationIcon/>,
        items:[  
            {type:'item',icon:<TextSnippetIcon/>,primary:'Negócios',href:'/app/register/negotiation/',id:'negotiation'}, 
        ],
    },  
    {
        type:'accordion',
        summary:'Administrador',
        icon:<SupervisorAccountIcon/>,
        items:[  
            {type:'item',icon:<PeopleAltIcon/>,primary:'Usuários',href:'/app/adm/users/',id:'adm_user'},
            {type:'item',icon:<WhatsAppIcon/>,primary:'WhatsApp',href:'/app/adm/whatsapp',id:'adm_wa'},
        ],
    },  
    {
       type:'accordion',
       summary:'Compartilhado',
       icon:<ShareIcon/>,
       items:[
         {type:'item',icon:<CloudIcon/>,primary:'Arquivos',href:'/app/shared/files'}
       ]
    },
  /*  {
        type:'accordion',
        summary:'Configurações',
        items:[
            {type:'item',icon:<PeopleAltIcon/>,primary:'Notificações',href:'/app/settings/notifications/',id:'conf_notifications'},
        ]
    },*/
   {type:'item',icon:<LogoutIcon/>,primary:'Deslogar',href:'/app/logout/'},
];


function Logo(){
    return (<a href='/'><img src={process.env.PUBLIC_URL+"/img/logo1.png"} width='40px'/></a>)
}

export class App extends React.Component{
    constructor(props){
        super(props);
        this.state={content:null,isLogged:false,isLoading:true,disabledItems:{}}
    }
    
    async checkIsLogged(){
        const isLogged=await userIsLogged();
        const location=window.location.pathname.replaceAll('/','');
        if(isLogged){
            changeLocationPathName(location=='applogin'?'/app/register/negotiation/':window.location.pathname);
            const isAdm=userIsAdm();
            this.setState({
                disabledItems:{
                    'adm_user':!isAdm,
                    'adm_wa':!isAdm,
                }
            })
        }else{
          switch(location){
              case 'appregister':
                 break;   
              default:
                changeLocationPathName("/app/login/");
                break;   
          }  
         
        }  
        this.setState({isLoading:false,isLogged:isLogged});
    }
    async logout(){
        this.setState({isLoading:true,isLogged:false});
        await logOut();
        this.checkIsLogged();
    }
    Logout(){
        this.logout();
        return (
            <p></p>
        )
    }
    componentDidMount(){
        this.updateUrlRef=this.updateUrl.bind(this);
        window.addEventListener('onurlupdate',this.updateUrlRef);
        this.checkIsLogged();
    }
    componentWillUnmount(){
        window.removeEventListener('onurlupdate',this.updateUrlRef);
    }
    updateUrl(){
       checkVersion();
       let content=null; 
       this.setState({content:content});
    }
    Content(){
       
       const Logout=this.Logout.bind(this); 
       return (<BrowserRouter>
            <Routes>
                <Route path="*" element={<NegotiationOrSchedule/>}/>
                <Route path="/app" element={<NegotiationOrSchedule/>}/>
                <Route path='/app/register'>
                  <Route path="negotiation" element={<NegotiationOrSchedule/>}/>
                </Route>
                <Route path="/app/adm">
                   <Route path="users" element={<AdmUsers/>}/>
                   <Route path='whatsapp' element={<WhatsAppConnection/>}/>
                </Route> 
                <Route path='/app/settings'>
                    <Route path='notifications' element={<div>Em Desenvolvimento</div>} />
                </Route>    
                <Route path='/app/shared'>
                    <Route path='files' element={<SharedFiles/>} />
                </Route>           
                <Route path="/app/logout" element={<Logout/>}/>                
            </Routes>
       </BrowserRouter>)
    }
    render(){
            const location=window.location.pathname.replaceAll('/','');
            return (
                <React.Fragment> 
                {this.state.isLoading?<Loading/>:
                  this.state.isLogged?
                  <React.Fragment>
            
                  <ResponsivelLayout disabledItems={this.state.disabledItems} listItems={listItems} logo={<Logo/>} content={this.Content()} />
                  </React.Fragment>
                  :
                  <React.Fragment>
                  {location==='appregister'?null:<SignIn/>}
                  </React.Fragment>
                }
                <Snack/>
                <div style={{position:'fixed',left:0,bottom:0,zIndex:5000,fontSize:'12px'}}>
                  <p>{`Versão ${formatVersion(CURRENT_VERSION)}`}</p>
                </div>
                </React.Fragment>
            )    
    }
}


function Loading(props){
   return (
    <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={true}
  >
    <CircularProgress color="inherit" />
  </Backdrop>
   )
}

