function getForm(form){
    let formElement=form;
    if(typeof(form)==='string'){
       formElement = document.getElementById(form);
    }
    return formElement;
}
function getFormFields(form){
    const searchFields=['INPUT','SELECT'];
    let fields=[];
    appendFoundFields(form);
    return fields;
    function appendFoundFields(elm){
        for(let i=0;i<elm.childElementCount;i++){
            let child=elm.children[i];
            let found=false;
            for(let j=0;j<searchFields.length;j++){
              if(child.tagName.toUpperCase()==searchFields[j]){
                found=true;      
                break;
              }  
            }
            if(found){
             fields.push(child);
            }else{
                appendFoundFields(child);
            }
        }
    }
}

export function checkFormValidity(form){
    const formElement=getForm(form);
    const formFields=getFormFields(formElement);
    for(let i=0;i<formFields.length;i++){
        if(!formFields[i].checkValidity())
           throw formFields[i];
    }
    return true;
}

export function sendForm(to,form,onreadystatechange){
    const formElement=getForm(form);
    console.log(form);
    var formData = new FormData(formElement);
    var request = new XMLHttpRequest();
    request.onreadystatechange=onreadystatechange;
    request.open("POST", to);
    request.send(formData);
}
export function formSendJSONAjax(to,form,onreadystatechange){
    const formElement=getForm(form);
    var formData = new FormData(formElement);
    var request = new XMLHttpRequest();
    request.open("POST", to);
    request.onreadystatechange=onreadystatechange;
    request.setRequestHeader("Content-type",'application/json');
    request.send(JSON.stringify(Object.fromEntries(formData)));
}