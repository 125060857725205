export function WhatsAppLink({number}){
   return (
      <a target="_blank" href={`https://wa.me/${number}`}>{number}</a>
   )
}


export function getNumberWithCountryDDD(number,ddd){
    const useDDD=ddd||'55';
    if(number.length>=13)
       return number;
    if(number.length==12){

       if(useDDD==number.slice(0,useDDD.length)){

          return `${number.slice(0,4)}9${number.slice(4)}`;  
       }
       return `${useDDD}${number}`  
    }
    if(number.length==11)
      return `${useDDD}${number}`;
    if(number.length==10)
      return `${useDDD}${number.slice(0,2)}9${number.slice(2)}`;
    return `${useDDD}${number}`;
} 
