import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {checkFormValidity} from "./../utils/form";
import { updateSnackStatus } from './Snack';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import { EmailTextField, PasswordTextField, UpperCaseTextField, getJustNumbers } from './Inputs';
import { sendServerRequest } from '../utils/sendreq';
import { URLS } from '../env/urls';
import { useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tab } from '@mui/material';
import { GeralField } from './field';
import { setAnyFirstLetterToUpper } from '../utils/string';
import {FormControlLabel,Switch} from '@mui/material';
export  function EditUser({open,close,onEdited,user}){
    const [tab,setTab]=React.useState(0);
    const [loading,setLoading]=React.useState(false);
    const [newPassword,setNewPassWord]=React.useState("");
    const [defaultName,setDefaultName]=React.useState("");
    const [defaultEmail,setDefaultEmail]=React.useState("");
    const [defaultPhone,setDefaultPhone]=React.useState("");
    const [isActive,setIsActive]=React.useState(false);
    const [editName,setEditName]=React.useState("");
    const [editEmail,setEditEmail]=React.useState("");
    const [editPhone,setEditPhone]=React.useState("");
    const [defaultCanUpload,setDefaultCanUpload]=React.useState(false);
    const [canUpload,setCanUpload]=React.useState(false);
    const updateData=React.useRef(false);
    const lastUser=React.useRef(false);
    const closeHandle=()=>{
       if(loading)
         return;
       close();
    }
    useEffect(()=>{
       if(lastUser.current!==user){
           lastUser.current=user;
           setTab(0);
           setLoading(false);
           setNewPassWord("");
           setDefaultName(user.first_name);
           setDefaultEmail(user.email);
           setDefaultPhone(user.phone||"");
           setDefaultCanUpload(user.canUploadFiles);
           setIsActive(user.is_active);
           setEditEmail(user.email);
           setEditName(user.first_name);
           setEditPhone(user.phone||"");
           setCanUpload(user.canUploadFiles)
           updateData.current=false;
       }
    })
    useEffect(()=>{
       if(loading){
          if(updateData.current){
            sendServerRequest('/server/adm/editDealer',JSON.stringify(updateData.current))
            .then(()=>{
                updateSnackStatus({
                  open:true,
                  severity:'success',
                  message:'Usuário Atualizado!',
              });
              setLoading(false);
              setNewPassWord("");
              if(updateData.current.deactive){
                 setIsActive(false);
              }else{
                 setIsActive(true);
              }
              if(updateData.current.name){
                  setDefaultName(updateData.current.name);
                  setEditName(updateData.current.name);
              }
              if(updateData.current.email){
                 setDefaultEmail(updateData.current.email);
                 setEditEmail(updateData.current.email);
              }
              if(updateData.current.phone){
                 setDefaultPhone(updateData.current.phone);
                 setEditPhone(updateData.current.phone);
              }
              if(updateData.current.canUploadFiles){
                  if(updateData.current.canUploadFiles=='yes'){
                    setDefaultCanUpload(true);
                  }else{
                    setDefaultCanUpload(false);
                  }
              }
              onEdited();
            })
            .catch(e=>{
                if(updateData.current.email){
                  updateSnackStatus({
                    open:true,
                    severity:'info',
                    message:'Esse email ja está registrado.',
                });
                }else{
                  updateSnackStatus({
                    open:true,
                    severity:'error',
                    message:'Algum erro aconteceu, atualize a página!',
                });
                }
                
              setLoading(false);
            })
          }
       }
    },[loading])
    return(
       <Dialog open={open} onClose={closeHandle}>
        <DialogTitle>{`${defaultName} - ${defaultEmail}`}</DialogTitle>
        <DialogContent>
          <TabContext value={tab}>
              <TabList onChange={(a,i)=>setTab(i)}>
                  <Tab label='Informações'/>
                  <Tab label='Senha'/>
                  <Tab label='Permissões'/>
                  <Tab label='Conta' />
              </TabList>
          
          <TabPanel value={0}>
               <GeralField onChange={setEditName} defaultValue={defaultName} fullWidth label='Nome Completo' editable filters={[setAnyFirstLetterToUpper]}/>
               <GeralField onChange={setEditEmail} defaultValue={defaultEmail} fullWidth label='Email' type='email' editable autoComplete='email' />
               <GeralField fullWidth label='Telefone' onChange={setEditPhone} defaultValue={defaultPhone} type='phone' editable autoComplete={'phone'} filters={[(s)=>getJustNumbers(s,13)]}/>
               <div style={{marginTop:'40px'}}>
               <LoadingButton disabled={editName?.trim()==defaultName&&editEmail?.trim()==defaultEmail&&editPhone?.trim()==defaultPhone}  onClick={changeInfo} loading={loading} fullWidth variant='contained'>Salvar</LoadingButton>
               </div>
          </TabPanel>
          <TabPanel value={1}>
                <p>É necessário no mínimo 8 caracteres.</p>
                <PasswordTextField value={newPassword} onChange={(e)=>setNewPassWord(e.target.value)} label='Nova Senha' fullWidth/>
                <div style={{marginTop:'40px'}}>
                 <LoadingButton  loading={loading} disabled={!newPassword||newPassword.length<8} onClick={changePassword} fullWidth variant='contained'>Mudar Senha</LoadingButton>
               </div>
          </TabPanel>
          <TabPanel value={2}>
             <div style={{textAlign:'center'}}>
              <FormControlLabel control={<Switch onChange={(e)=>{setCanUpload(e.target.checked)}} name={"is_private"}  checked={canUpload}/>} label='Salvar arquivos.'/>
             </div>
             <LoadingButton loading={loading} onClick={()=>update({canUploadFiles:canUpload?'yes':'no'})} fullWidth variant='contained' disabled={defaultCanUpload==canUpload}>Modificar Permissões</LoadingButton>
          </TabPanel>
          <TabPanel value={3}>
               <LoadingButton variant='contained' onClick={()=>update({deactive:isActive})} loading={loading} disabled={user.user_level=='adm'} color={isActive?'error':'info'} fullWidth>{isActive?'Desativar Usuário':'Ativar Usuário'}</LoadingButton>
          </TabPanel>
          </TabContext>
        </DialogContent>
        <DialogActions>
            <Button variant='contained' disabled={loading} onClick={closeHandle}>Sair</Button>
        </DialogActions>
        </Dialog>
    );
    function changeInfo(){
         let data={

         }
         if(editPhone!==defaultPhone){
            if(editPhone.length<10){
              updateSnackStatus({
                open:true,
                severity:'info',
                message:'Telefone deve ter no mínimo 10 digitos!'
              });
              return;
            }else{
              data.phone=editPhone;
            }
         }
         if(editName!==defaultName){
              if(editName.trim().split(" ").length<2){
                  updateSnackStatus({
                      open:true,
                      severity:'info',
                      message:'Digite o nome completo!'
                  });
                  return;
              }else{
                 data.name=editName;
              }
         }
         if(editEmail!==defaultEmail){
            if(!checkEmail(editEmail)){
              updateSnackStatus({
                open:true,
                severity:'info',
                message:'Digite um email valido!'
              });
              return;
            }else{
               data.email=editEmail;
            }
         }
         update(data);
    }
    function changePassword(){
        update({password:newPassword});
    } 
    function update(data){
      updateData.current={
        id:user.id,
        ...data,
      }
      setLoading(true);
    }
}

export  function Register({open,close,onRegistered}) {
  const [loading,setLoading]=React.useState(false);  
  const closeHandle=()=>{
       if(loading)
         return;
        close();
  }
  useEffect(()=>{
    return ()=>{}
  },[]);
  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = new FormData(event.currentTarget);
    try{
        checkFormValidity(event.currentTarget);
    }catch(e){
        e.reportValidity();
        return;
    }
    const start=new Date();
    const obj=Object.fromEntries(form);
    if(!obj.name||obj.name.split(' ').length<2){
      updateSnackStatus({
        severity:"info",
        message:"Por favor, cadastre seu nome completo.",
        open:true,
    });
    return;
    }
    if(!checkEmail(obj.email)){
        updateSnackStatus({
            severity:"info",
            message:"Digite um email valido.",
            open:true,
        });
        return;
    }
    if(obj.password.length<8){
      updateSnackStatus({
        severity:"info",
        message:"É necessário no mínimo 8 caracteres.",
        open:true,
    });
    return;
    }
    setLoading(true);
    
    if(localStorage.getItem('ref')){
        obj['affiliate']=localStorage.getItem('ref').toUpperCase();
    }
    const data=JSON.stringify(obj);
    const result=await sendServerRequest(URLS.user.register,data);
    const end=new Date();
    setTimeout(checkResult,Math.max(750-(end-start),0));
    async function checkResult(){
        if(!result.data.hasError){
            updateSnackStatus({message:"Usuário registrado com sucesso.",open:true,severity:"success"});
            onRegistered();
          }else{
              if(result.data.error==="User Exist"){
                updateSnackStatus({message:"Usuário já está cadastrado.",open:true,severity:"info"});
              }else if(result.data.error=="Must be a complete name."){
                updateSnackStatus({message:"Por favor, cadastre seu nome completo.",open:true,severity:"info"});
              }else{
                updateSnackStatus({message:"Algum erro, atualize a página.",open:true,severity:"error"});
              }  
             
          }
          setLoading(false);
    }
    
  };

  return (
      <Dialog open={open} onClose={closeHandle}>
        <DialogContent>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar  src={process.env.PUBLIC_URL+'/img/logo.png'} sx={{ m: 1, bgcolor:'secodary.main'}}/>
          <Typography component="h1" variant="h5">
            Criar Uma Conta
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <UpperCaseTextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Nome"
              name="name"
              autoComplete="name"
              autoFocus
            />
            <EmailTextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
            />
            
            <PasswordTextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Senha"
              id="password"
              autoComplete="current-password"
            />
            <LoadingButton 
              type="submit"
              fullWidth
              variant="contained"
              loading={loading}
              sx={{ mt: 3, mb: 2 }}
            >
              Registrar
            </LoadingButton >
            <Grid container>
              <Grid item xs>

              </Grid>
              <Grid item>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Typography variant="subtitle2" align="center"></Typography>


      </Container>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeHandle} disabled={loading} variant='contained'>Fechar</Button>
      </DialogActions>
      </Dialog>
  );
}


function checkEmail(email){
  const atIndex=email.indexOf("@");
  if(atIndex<1)
     return false;
  const after=email.slice(atIndex+1);
  if(after.length<3)
    return false;
  if(after.indexOf('@')>=0)
    return false;
  const periodIndex=after.indexOf('.');
  if(periodIndex<1||periodIndex>=after.length-1)
     return false;
  return true;
}