import { Button, Snackbar } from "@mui/material";
import { Alert } from "@mui/material";
import React from "react";


export var updateSnackStatus=()=>null;
export class Snack extends React.Component{
    constructor(props){
        super(props);
        this.state={open:false,message:"",severity:"info",autoHideDuration:6000}
        updateSnackStatus=this.setState.bind(this);
    }
    snackClose(){
        this.setState({open:false});
    }

    render(){
        return (

            <Snackbar
                        open={this.state.open}
                        onClose={()=>{this.snackClose()}}         
                        autoHideDuration={this.state.autoHideDuration||6000}      
                    >
                        <Alert onClose={()=>{this.snackClose()}}
                               severity={this.state.severity}
                               sx={{ width: '100%' }}
                               action={
                                  this.state.open?.action?<Button onClick={this.state.open.action.onClick}>{this.state.open.action.label}</Button>:null
                               }      
                        >
                            {this.state.message}
                        </Alert>
            </Snackbar>
        )
    }
}