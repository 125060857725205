import { URLS } from "../env/urls";

import { sendServerRequest } from "./sendreq";
export async function userIsLogged(){
    try{
       let result=await sendServerRequest(URLS.user.info,"","POST");
       if(result.status==200){
         window.userData=result.data.data;
         return true;
       }else{
         return false;
       }  
    }catch(e){
         return false;
    }
}
export function reloadIfUserIsNotLogged(target){
    if(target.status==401){
        const data=JSON.parse(target.responseText);
        if(data.error=='User Must Be Logged'){
          window.location.reload();
        }
    }
}
export  function userIsAdm(){
  if(window.userData){
      if(window.userData.permissionType==='adm'){
          return true;
      }
  }
  return false;
}
export async function logOut(){
  try{
     let result=await sendServerRequest(URLS.user.logout,"","POST");
     if(result.status==200){
       return true;
     }else{
       return false;
     }  
  }catch(e){
       return false;
  }
}

export async function logIn(data){
  try{
     let result=await sendServerRequest(URLS.user.login,data,"POST");
     if(result.status==200){
       return !result.data.hasError;
     }else{
       return false;
     }  
  }catch(e){
       return false;
  }
}
