import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {checkFormValidity} from "./../utils/form";
import {logIn} from "./../utils/user";
import { Snack } from './Snack';
import { updateSnackStatus } from './Snack';
import { LoadingButton } from '@mui/lab';
import { PasswordTextField } from './Inputs';

function Copyright(props) {
  return (
    <Typography variant="body2" fontSize={'10px'} color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link  target='_blank' color="inherit" href="https://github.com/JimyRFP">
        Rafael Floriani Pinto
      </Link>{' '}
      2022
      {'.'}
    </Typography>
  );
}



export default function SignIn() {
  const [loading,setLoading]=React.useState(false);  
  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = new FormData(event.currentTarget);
    try{
        checkFormValidity(event.currentTarget);
    }catch(e){
        e.reportValidity();
        return;
    }
    const start=new Date();
    setLoading(true);
    const data=JSON.stringify(Object.fromEntries(form));
    const result=await logIn(data);
    const end=new Date();
    setTimeout(checkResult,Math.max(750-(end-start),0));
    function checkResult(){
        if(result){
            window.location.reload();
          }else{
              setLoading(false);
              updateSnackStatus({message:"Senha Invalida",open:true,severity:"error"});
          }
    }
    
  };

  return (

      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar src={process.env.PUBLIC_URL+'/img/logo.png'} sx={{ m: 1, bgcolor:'secodary.main'}}/>
            
          <Typography component="h1" variant="h5">
            Login COCASA
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <PasswordTextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Senha"
              id="password"
              autoComplete="current-password"
            />
            <LoadingButton 
              type="submit"
              fullWidth
              variant="contained"
              loading={loading}
              sx={{ mt: 3, mb: 2 }}
            >
              Logar
            </LoadingButton >
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  {1?null:"Esqueceu Senha?"}
                </Link>
              </Grid>
              <Grid item>
               
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Typography variant="subtitle2" align="center"></Typography>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
  );
}