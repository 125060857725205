import { MenuItem, TextField } from "@mui/material";
import {Select} from "@mui/material";
import {FormControl} from "@mui/material";
import {InputLabel} from "@mui/material";
import {InputAdornment} from "@mui/material";
import { IconButton } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import { useState } from "react";
export function GeralField({defaultValue,
                            onChange,
                            type,
                            readOnly,
                            editable,
                            filters,
                            value,
                            name,
                            fullWidth,
                            margin,
                            helperText,
                            autoComplete,
                            multiline,
                            label}){
  const [editMode,setEditMode]=useState(false);                            
  return(
      <TextField
        type={type||'text'}
        defaultValue={defaultValue}
        label={label}
        name={name}
        value={value}
        fullWidth={fullWidth}
        autoComplete={autoComplete}
        margin={margin||'normal'}
        multiline={multiline}
        onChange={onChangeValue}
        helperText={helperText}
        disabled={editable&&!editMode}
        InputProps={{
          endAdornment:editable?(<InputAdornment position="end"> 
          <IconButton onClick={()=>{setEditMode(!editMode)}}>
            {editMode?<CheckIcon/>:<EditIcon/>}
          </IconButton>
         </InputAdornment>):null,
         readOnly:readOnly
        }}
      />
  )
  function onChangeValue(e){
      let useValue=e.target.value;
      if(filters){
        for(let f of filters){
            useValue=f(useValue);
        }
      }
      e.target.value=useValue;
      if(onChange)
        onChange(useValue);
  }
}

export function GeralSelectField({defaultValue,items,onChange,label,editable}){
   const [editMode,setEditMode]=useState(false);   
   return(
    <FormControl fullWidth margin="normal">
    <InputLabel id={"select-label-"+label}>{label}</InputLabel>
    <Select
      labelId={"select-label-"+label}
      id={"select-id-"+label}
      label={label}
      disabled={editable&&!editMode}
      defaultValue={defaultValue}
      onChange={onChangeItem}
      endAdornment={editable?(<InputAdornment position="end"> 
          <IconButton onClick={()=>{setEditMode(!editMode)}}>
            {editMode?<CheckIcon/>:<EditIcon/>}
          </IconButton>
         </InputAdornment>):null}
    >
      {
        items.map((e)=>{
            return (
                <MenuItem value={e}>{e}</MenuItem>
            )
        })
      }
    </Select>
  </FormControl>
   )
   function onChangeItem(e){
      if(onChange)
       onChange(e.target.value)
   }
}