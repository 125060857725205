import { id } from "date-fns/locale";
import {serverDateStringToDate} from "./date";
import { getJustNumbers } from "../MyComponents/Inputs";
export function setAnyFirstLetterToUpper(value){
       let names=value.split(' ');
       let result='';
       names.map((v)=>{
         result+=(v.slice(0,1).toUpperCase()+v.slice(1)+" ");
       });
       result=result.slice(0,result.length-1);
       return result;
}

export function getJustChars(string,allowSpace=true){
    let ret='';
    for(let i=0;i<string.length;i++){
        let char=string[i].charCodeAt();
        if((char>64 && char<91) || (char>96 && char<123) || (allowSpace && char==32)){
           ret+=string[i];
        }
    }
    return ret;
}

export function setFirstLetterToUpper(v){
  return v.slice(0,1).toUpperCase()+v.slice(1);
}
export function removeAccentuation(str) {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export function getJustCharsAndNumbers(string,allowSpace=true){
  let ret='';
  for(let i=0;i<string.length;i++){
      let char=string[i].charCodeAt();
      if((char>64 && char<91) || (char>96 && char<123) || (char>47 && char<58) || (allowSpace && char==32)){
         ret+=string[i];
      }
  }
  return ret;
}

export function formatCurrency(value,currency='R$',digits=2){
  let useValue=typeof(value)=='string'?parseFloat(value):value;
  return `${currency} ${useValue.toFixed(digits)}`
}

export function formatDateString(str,hour=false){
  let date=str instanceof Date?str:serverDateStringToDate(str);
  const day=setZeroTenLower(date.getDate());
  const month=setZeroTenLower(1+date.getMonth());
  const year=1900+date.getYear();
  const fHour=hour?` ${setZeroTenLower(date.getHours())}:${setZeroTenLower(date.getMinutes())}`:"";
  return `${day}/${month}/${year}${fHour}`;
  function setZeroTenLower(number){
      return number>9?number:`0${number}`;
  }
}


export function formatGlobalDateString(e,hour=false){
  let date=new Date(e);
  return formatDateString(date,hour);
}

export function stringInsertVar(str,vars){
  const len=str.length;  
  let index=0;
  let retStr="";
  while(index<len){
      let char=str.charAt(index);
      index++;
      if(char!=="$"){
        retStr+=char;
        continue; 
     }  
     if(index>=len)  
        continue;
    char=str.charAt(index);
    if(char!=='{'){
        retStr+="$";
        continue;
    }   
    index++;
    const names=getVarName();
    retStr+=getVarValue(names)    
  }
  function getVarName(){
      let names=[]
      let varName="";
      let isClose=false;
      while(index<len){
        let char=str.charAt(index);
        if(char=='{')
           throw `Var must be closed with \'}\' Index ${index}`;        
        index++;
        if(char=='.' || char=='}'){
          if(varName=="")
            throw `Empty var name Index ${index-1}`;
          if(varName.charCodeAt(0)>47 && varName.charCodeAt(0)<58)
             throw `Var Name Can't start with number Index ${index-1}`
          names.push(varName);
          varName="";
          if(char=='}'){
             isClose=true;
             break;
          }
          continue;
        }
        varName+=char;
      }
      if(!isClose)
        throw `Var must be closed with \'}\'`;
      return names;
  }
  function getVarValue(names){
       let current=vars[names[0]];
       for(let i=1;i<names.length;i++){
            if(!current)
                throw `Unknown var ${names.join('.')}`;
            current=current[names[i]];
       }
       return (typeof(current)=='function'?current():current)
  }
  return retStr;
}