import { URLS } from "../env/urls";
import { sendServerRequest } from "../utils/sendreq";
import { promiseState } from "../utils/promise";
export async function getDeviceInfo(){
      if(window.getDeviceInfo){
          let status=await promiseState(window.getDeviceInfo);
          if(status=='pending'){
             return window.getDeviceInfo;
          }
      }
      window.getDeviceInfo=sendServerRequest(URLS.session.host,JSON.stringify({
        clientId:1,
        waLib:'WA_WEB_JS',
      }));
      return window.getDeviceInfo;
}